import React from 'react';
import './App.css';
import logo from './logo.svg';

const Dashboard: React.FC = () => {
	return (
		<div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                Dashboard
            </header>
        </div>
	)
}

export default Dashboard;