import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
// import './styles.css';

const Error404: React.FC = () => {
    let location = useLocation();
    let pathName = location.pathname;

    return (
        <>
            <Helmet>
                <title>Página não encontrada - Super App Energizou</title>
            </Helmet>

            <main className="pageError">
                <h1>
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                </h1>

                <h2>Página não encontrada!</h2>

                <Link to={pathName.indexOf('/admin')>=0 ? '/admin' : '/'} className="btnHome">
                    <FiArrowLeft size="18" />
                    <span>Voltar a página inicial</span>
                </Link>
            </main>
        </>
    );
}

export default Error404;