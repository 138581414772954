import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Pages
import { Error404, Dashboard } from '~/pages';

const SystemRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Auth */}

                {/* Admin */}
                <Route path="/" element={<Dashboard/>} />

                {/* Error */}
                <Route path="/404" element={<Error404/>} />
                <Route path="/*" element={<Navigate to="/404" replace />} />
            </Routes>
        </BrowserRouter>
    )
}

export default SystemRoutes;